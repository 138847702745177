@import 'node_modules/primeflex/primeflex.scss';

@import 'prismjs/themes/prism-coy.css';
@import './AppDemo.scss';


/* Estilização para navegadores Webkit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 10px; /* Largura da barra de rolagem */
  background-color: #f0f0f0; /* Cor de fundo do scroll */
}

::-webkit-scrollbar-thumb {
  background-color: #4f5170; /* Cor da barra de rolagem */
  border-radius: 5px; /* Borda arredondada da barra de rolagem */
}

.pend .p-fieldset-legend {
 color: #fd0000;
}
